import restaurant from "./images/adevar.jpg";
import img_card from "./images/3m1.jpg";
import imgheaderrestaurant from "./images/headerrestaurant.jpg";
import imgheader from "./images/header.webp";
import imgheaderm from "./images/headermb.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Vadim",
        mireasa: "Eugenia",
        data: "17 Iunie 2023",
        data_confirmare: "10 iunie 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "eugeniaflorea01@gmail.com", 
       tel: "+37368963210",
       phone: "tel:+447859623202",
       viber: "viber://chat?number=%2B37368963210",
       whatsapp: "https://wa.me/+37368963210",
       messenger: "https://www.messenger.com/t/",
       tel1: "+37360973954",
       phone1: "tel:+37360973954",
       viber1: "viber://chat?number=%2B37360973954",
       whatsapp1: "https://wa.me/+37360973954",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Vocea Adevărului",
            data: "17 iunie 2023, sâmbătă, ora 15:00",
            adress: "Strada Putnei 27, Chișinău",
            harta: "https://goo.gl/maps/uwjHR6vxs1iEbhxTA",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10874.054507798537!2d28.8456452!3d47.049774!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d072a611e83%3A0xa4cf8a7d6a684e8e!2z0JPQvtC70L7RgSDQmNGB0YLQuNC90Ys!5e0!3m2!1sro!2sro!4v1684394904062!5m2!1sro!2sro" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "Sunteți invitați să ne bucurăm la celebrarea căsătoriei noastre!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Vă așteptăm să împărtășim bucuria acestor clipe unice!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Acum dar rămân acestea trei: credința, speranța, dragostea, dar cea mai mare dintre ele este dragostea.\" – Epistola către Corinteni 13:13 ",
        }
    ],

}

export default data;